import React from 'react'

const Partnerships = () => {
    return(
        <div className="aboutSubSection">
            <h1>Partnerships</h1>
            <p>
                Maxarth staff work in close collaboration with a multi-disciplinary network
                of partners from around the nation. We engage in partnerships with subject matter 
                experts and technology companies to combine our skills, knowledge, and experience 
                to provide the most timely, accurate, and relevant analysis.                                     
            </p>
        </div>
    )
}

export default Partnerships