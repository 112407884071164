import React from 'react'
import '../styles.css'
import redSide from '../../../images/redCubeSide.svg'
import blueSide from '../../../images/blueCubeSide.svg'
import yellowSide from '../../../images/yellowCubeSide.svg'
import { useSelector } from 'react-redux'

const Rubix = () => {
    const hamburgerMenuIsOpen = useSelector(state => state.hamburger.overlay)

    const rubixOpacity = hamburgerMenuIsOpen ? '0' : '1'

    return (
        <div id="cube" style={{opacity: rubixOpacity}}>
            <div className="face one" ><img alt="Rubix red" src={redSide}/></div>
            <div className="face two" ><img alt="Rubix blue"src={blueSide}/></div>
            <div className="face three" ><img alt="Rubix red" src={redSide}/></div>
            <div className="face four" ><img alt="Rubix blue"src={blueSide}/></div>
            <div className="face five" ><img alt="Rubix yellow"src={yellowSide}/></div>
            <div className="face six" ><img alt="Rubix yellow" src={yellowSide}/></div>
        </div>
    )
}

export default Rubix