import React from 'react'

const Panel = ({id, title, content}) => 
    <figure className="cardWrapper" id={id}>
        <h1 className="title">{title}</h1>
        <h2 className="hoverTitle">{title}</h2>
        <ul className="content">
            {content.map(
                item => <li> {item} </li>
            )}
        </ul>
    </figure>

const ServicePanels = () =>
    <div className="panelsWrapper">
        <Panel 
            id="SDP"
            title="Synthetic Data Products"
            content={[
                "Synthesize information from a variety of data sources into centralized databases.",
                "Create simulation and optimization tools.",
                "Design synthetic decision support systems.",
                "Develop synthetic datasets for privacy protection."
            ]}
        />
        <Panel 
            id="FPA"
            title="Forcasting and Predictive Analysis"
            content={[
                "Design and undertake individual risk or needs assessment and validation studies.",
                "Perform responsivity analysis; model, trace, and predict risk trajectories.",
                "Analyze and predict behavioral heterogeneity.",
                "Generate short-, medium-, and long-term forecasts."
            ]}
        />
        <Panel 
            id="CB"
            title="Capacity Building"
            content={[
                "Help clients’ teams find appropriate uses of empirical evidence.",
                "Teach practical ways to devise and utilize simulation tools.",
                "Demonstrate how to appreciate the powers and limitations of statistical and mathematical analysis."
            ]}
        />   
        <Panel 
            id="PEPA"
            title="Program Evaluation and Policy Analysis"
            content={[
                "Evaluate programs and practices.",
                "Design and undertake experimental, quasi-experimental, or observational studies.",
                "Devise micro-simulation tools.",
                "Conduct policy analysis."
            ]}
        />                      
    </div>

export default ServicePanels