import React from 'react'
import PageHeader from '../../components/PageHeader.js'
import './styles.css'

const ContactCard = ({heading, content, href}) =>
    <div id="contactCard">
        <h1>{heading}</h1>
        <a href={href} target="_blank" rel="noreferrer">{content}</a>
    </div>

const Contacts = () => {
    return (
        <div className="contactWrapper">
            <PageHeader
                page="Contacts"
                title="Contacts"
                subtitle="To discuss a project or to obtain more information about the services we provide, our pricing, and to request copies of publications, please contact us directly by email or phone."
            />
            <div id="two-col-two-rows">
                <ContactCard heading="Address" content="12215 Fellowship Lane North Potomac, MD 20878" href="https://goo.gl/maps/GfwMtzXd2C1rmGa69"/>
                <ContactCard heading="Email" content="abhati@maxarth.com" href="mailto:abhati@maxarth.com"/>
                <ContactCard heading="Phone" content="301.789.2966" href="tel:301-789-2966"/>
                <ContactCard heading="Fax" content="301.880.7152" href="fax:301-880-7152"/>
            </div>
        </div>
    )
}

export default Contacts