import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './pages/home'
import About from './pages/about'
import Services from './pages/services'
import Contacts from './pages/contacts'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import ScrollToTop from './ScrollToTop.js'

function App() {  
  return (
    <Router className="App">
        <ScrollToTop />
        <Navigation/>
        <Switch className="Body">
          <Route path="/about">
            <About />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/contacts">
            <Contacts />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>

        <Footer/>
    </Router>
  );
}

export default App;
