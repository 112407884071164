import React from 'react'
import Rubix from './components/Rubix'
import './styles.css'

const Home = () => {
    return (
        <div className="homeWrapper">
            <Rubix />
            <h1>Creative data and analytical solutions</h1>
            <h2>for real-world problems and opportunities.</h2>
        </div>
    )
}

export default Home