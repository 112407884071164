import React from 'react'
import checkmark from '../../../images/checkmark.svg'
import '../styles.css'

const ClientList = () => {
    return(
        <div id="clientList">
            <h2>We cater to the data and analysis needs of:</h2>
            <div className="two-column-table">
                <ul>
                    <li><img src={checkmark} alt="checkmark"/> Governmental Entities</li>
                    <li><img src={checkmark} alt="checkmark"/> Educational Institutions</li>
                    <li><img src={checkmark} alt="checkmark"/> Research Centers</li>
                    <li><img src={checkmark} alt="checkmark"/> International Organizations</li>
                </ul>
                <ul>
                    <li><img src={checkmark} alt="checkmark"/> Professional Associations</li>
                    <li><img src={checkmark} alt="checkmark"/> Non-Profit Organization</li>
                    <li><img src={checkmark} alt="checkmark"/> For-Profit Companies</li>
                    <li><img src={checkmark} alt="checkmark"/> Private Individuals</li>
                </ul>
            </div>
        </div>
    )
}

export default ClientList