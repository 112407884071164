import React from 'react'
import tripleCubes from '../images/tripleCubes.svg'
import './PageHeader.css'

const PageHeader = ({title, subtitle, page}) => {
    return (
        <div id="pageHeader">
            <h1>{title}</h1>
            <h2 id={page}>{subtitle}</h2>
            <img src={tripleCubes} alt="red, blue and yellow cubes"/>
        </div>
    )
}

export default PageHeader