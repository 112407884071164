import title from '../images/title.svg';
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => { 
    return (
        <footer>
            <div id="copyright">Copyright © 2021 Maxarth, LLC</div>
            <Link to="/"><img src={title} alt="company-logo-text"/></Link>
            <nav>
                <ul>
                    <Link to="/about">About</Link>
                    <Link to="/services">Services</Link>
                    <Link to="/contacts">Contacts</Link>
                </ul>
            </nav>
        </footer>  
    )
}

export default Footer