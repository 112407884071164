import React from 'react'
import PageHeader from '../../components/PageHeader.js'
import ServicePanels from './components/ServicePanels'
import './styles.css'

const Services = () => {
    return (
        <div className="servicesWrapper">
            <PageHeader
                page="Services"
                title="Services"
                subtitle="We specialize in the collection, synthesis, and analysis of data to inform policy and practice."
            />
            <ServicePanels/>
        </div>
    )
}

export default Services