const initialState = {
    overlay: false
  }

const hamburgerReducer = (state=initialState, action) => {
  
    switch (action.type) {
      case 'OPEN_BURGER': 
        return {
          ...state,
          overlay: true
        }
  
      case 'CLOSE_BURGER': 
      return {
        ...state,
        overlay: false
      }
  
      default:
        return state
    }
}
  
  export const openBurger = () => {
    return {
        type: 'OPEN_BURGER'
    }
  }
  
  export const closeBurger = () => {
    return {
      type: 'CLOSE_BURGER'
    }
  }
  
  export { hamburgerReducer }