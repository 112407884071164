import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo.svg'
import './Navigation.scss'
import { useDispatch, useSelector } from 'react-redux'
import { openBurger, closeBurger } from '../reducers/hamburgerReducer'
import { Menu, X } from 'react-feather'

const Navigation = () => {
    const [ shadowVisible, setShadowVisible ]  = useState(false)

    const hamburgerMenuIsOpen = useSelector(state => state.hamburger.overlay)

    const dispatch = useDispatch()

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const yOffset = window.pageYOffset;

            yOffset === 0 ? setShadowVisible(false) : setShadowVisible(true)
        })
    })

    const overlayVisibility = hamburgerMenuIsOpen ? 'visible' : 'hidden'

    const menuDisplay = hamburgerMenuIsOpen ? 'none' : 'block'

    const xDisplay = hamburgerMenuIsOpen ? 'block' : 'none'

    const handleHambugerClick = () => {
        hamburgerMenuIsOpen ? dispatch(closeBurger()) : dispatch(openBurger())
    }

    const boxShadow = shadowVisible
        ? '0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1)'
        : 'none'

    const height = shadowVisible
        ? '9vh'
        : '15vh'

    return (
        <div>
            <nav className="mobile">
                <Link to="/"><img src={logo} alt="title-logo"/></Link> 
                <label for="overlay-input" id="overlay-button" onClick={handleHambugerClick}>
                    <Menu style={{display: menuDisplay, width: '15vw', height: '6vh', color: '#545454'}}/>
                    <X style={{display: xDisplay, width: '15vw', height: '6vh', color: '#545454'}}/>
                </label>
                <div id="overlay" style={{visibility: overlayVisibility}}>
                    <ul>
                        <li><Link to="/about" onClick={handleHambugerClick}>About</Link></li>
                        <li><Link to="/services" onClick={handleHambugerClick}>Services</Link></li>
                        <li><Link to="/contacts" onClick={handleHambugerClick}>Contacts</Link></li>
                    </ul>
                </div>
            </nav>
            <div className="navbar" style={{boxShadow: boxShadow, height: height}} id="nav">
                <Link to="/"><img src={logo} alt="title-logo"/></Link> 
                <nav className="desktop">
                    <Link to="/about">About</Link>
                    <Link to="/services">Services</Link>
                    <Link to="/contacts">Contacts</Link>
                </nav>
            </div>
        </div>

    )
}

export default Navigation