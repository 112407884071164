import React from 'react'
import PageHeader from '../../components/PageHeader.js'
import ClientList from './components/ClientList.js'
import Leadership from './components/Leadership.js'
import Partnerships from './components/Partnerships.js'
import './styles.css'

const About = () => {
    return (
        <div  className="aboutWrapper">
            <PageHeader
                page="About"
                title="About Us"
                subtitle="Our goal is to supply every client with actionable knowledge and practical tools to make decisions with confidence."
            />
            <ClientList/>
            <Leadership/>
            <Partnerships/>
        </div>
    )
}

export default About