import React from 'react'
import { Mail } from 'react-feather'

const Leadership = () => {
    return(
        <div className="aboutSubSection">
            <h1>Leadership</h1>
            <h2>Avinash Bhati, Ph.D.</h2>
            <h3>FOUNDER</h3>
            <a href="mailto:abhati@maxarth.com"><Mail width="5vw" stroke="#545454" stroke-width="1"/></a>
            <p>
                Avinash Bhati, Ph.D., brings to Maxarth over ten years of experience conducting applied 
                empirical research addressing challenging public policy questions. Dr. Bhati earned a Ph.D. 
                in Economics from the American University (Washington, DC) in 2001 and has since successfully 
                lead several research efforts supported by the U.S. National Science Foundation, 
                the U.S. Department of Justice, the Court Services and Offender Supervision Agency for the 
                District of Columbia, the American Statistical Association, and several foundations. 
                He has consulted with several universities, research organizations, and practitioners. 

                Dr. Bhati is the author of numerous articles and reports. His multi-disciplinary work can 
                be found in such publications as Criminology, Econometric Reviews, Journal of Quantitative 
                Criminology, Journal of Criminal Law and Criminology, Sociological Methodology, and Criminal 
                Justice Policy Review. He has served on the editorial board of the Journal of Quantitative Criminology.                    
            </p>
        </div>
    )
}

export default Leadership